import React, { createContext, useContext, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

interface DialogRequest {
  type: 'alert' | 'confirm' | 'prompt' | 'custom';
  title?: string;
  message?: string;
  customProps?: any; // used for "prompt", or "custom"
  // The resolvers for the Promise
  resolve: (value: any) => void;
  reject: (reason?: any) => void;
}

interface DialogsContextValue {
  alert: (message: string, title?: string) => Promise<void>;
  confirm: (message: string, title?: string) => Promise<boolean>;
  prompt: (
    message: string,
    options?: { title?: string; defaultValue?: string; okText?: string; cancelText?: string }
  ) => Promise<string | null>;
  customDialog: <T = any>(component: React.ReactNode, props?: any) => Promise<T>;
}

export const DialogsContext = createContext<DialogsContextValue | null>(null);

export const useDialogs = (): DialogsContextValue => {
  const ctx = useContext(DialogsContext);
  if (!ctx) {
    throw new Error('useDialogs must be used within DialogsProvider');
  }
  return ctx;
};

export const DialogsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentRequest, setCurrentRequest] = useState<DialogRequest | null>(null);
  const [promptValue, setPromptValue] = useState<string>('');

  // ---- Core: open any "DialogRequest" with a promise ----
  const openDialog = (request: Omit<DialogRequest, 'resolve' | 'reject'>) => {
    return new Promise<any>((resolve, reject) => {
      setCurrentRequest({
        ...request,
        resolve,
        reject,
      });
    });
  };

  // ---- Public APIs ----
  const alert = (message: string, title?: string) => openDialog({ type: 'alert', message, title });

  const confirm = (message: string, title?: string) =>
    openDialog({
      type: 'confirm',
      message,
      title,
    }) as Promise<boolean>;

  const prompt = (
    message: string,
    options?: { title?: string; defaultValue?: string; okText?: string; cancelText?: string }
  ) =>
    openDialog({
      type: 'prompt',
      message,
      title: options?.title,
      customProps: {
        defaultValue: options?.defaultValue ?? '',
        okText: options?.okText ?? 'OK',
        cancelText: options?.cancelText ?? 'Cancel',
      },
    }) as Promise<string | null>;
  const customDialog = <T = any>(component: React.ReactNode, props?: any): Promise<T> => {
    return openDialog({
      type: 'custom',
      customProps: {
        component, // your custom component
        props,     // any props you want to pass to it
      },
    }) as Promise<T>;
  };


  // ---- Handling user actions in the rendered dialog(s) ----
  const handleClose = (value: any) => {
    if (!currentRequest) return;
    currentRequest.resolve(value);
    setCurrentRequest(null);
    setPromptValue('');
  };

  const handleCancel = () => {
    if (!currentRequest) return;
    if (currentRequest.type === 'confirm') {
      currentRequest.resolve(false);
    } else if (currentRequest.type === 'prompt') {
      currentRequest.resolve(null);
    } else {
      // alert, custom => no special cancel logic (resolve undefined)
      currentRequest.resolve(undefined);
    }
    setCurrentRequest(null);
    setPromptValue('');
  };

  // ---- Render the correct dialog based on `currentRequest.type` ----
  const renderDialogContent = () => {
    if (!currentRequest) return null;

    const { type, message, title, customProps } = currentRequest;

    switch (type) {
      case 'alert':
        return (
          <Dialog open onClose={() => handleClose(undefined)}>
            {title && <DialogTitle>{title}</DialogTitle>}
            {message && <DialogContent>{message}</DialogContent>}
            <DialogActions>
              <Button onClick={() => handleClose(undefined)} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        );

      case 'confirm':
        return (
          <Dialog open onClose={() => handleCancel()}>
            {title && <DialogTitle>{title}</DialogTitle>}
            {message && <DialogContent>{message}</DialogContent>}
            <DialogActions>
              <Button onClick={handleCancel} color="primary">
                Anuluj
              </Button>
              <Button onClick={() => handleClose(true)} color="secondary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
        );

      case 'prompt':
        return (
          <Dialog open onClose={handleCancel}>
            {title && <DialogTitle>{title}</DialogTitle>}
            {message && <DialogContent>{message}</DialogContent>}
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                fullWidth
                value={promptValue || customProps?.defaultValue || ''}
                onChange={(e) => setPromptValue(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancel}>{customProps?.cancelText || 'Anuluj'}</Button>
              <Button onClick={() => handleClose(promptValue)} color="primary">
                {customProps?.okText || 'OK'}
              </Button>
            </DialogActions>
          </Dialog>
        );

      case 'custom': {
        // The customProps include the custom component and any props
        const {component, props} = customProps || {};

        return (
            <Dialog open onClose={handleCancel} PaperProps={{ sx: { borderRadius: 2 } }} >
              {React.isValidElement(component)
                  ? React.cloneElement(component, {
                    ...props,
                    onClose: handleClose,
                    onCancel: handleCancel,
                  })
                  : null}
            </Dialog>
        );
      }

      default:
        return null;
    }
  };

  return (
    <DialogsContext.Provider
      value={{
        alert,
        confirm,
        prompt,
        customDialog,
      }}
    >
      {children}
      {renderDialogContent()}
    </DialogsContext.Provider>
  );
};
