import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { APIManufacturer } from '../../../api/types/Manufacturer';
import ManufacturerTableRowMoreMenu from './ManufacturerTableRowMoreMenu';

interface ManufacturerTableRowProps {
  manufacturer: APIManufacturer;
}

export default function ManufacturerTableRow({ manufacturer }: ManufacturerTableRowProps) {
  const { id, name, address, responsible_person } = manufacturer;

  const addressStr = `${address.street}, ${address.city}, ${address.postal_code}, ${address.country_code}`;

  return (
    <TableRow key={`manufacturer-row-${id}`} hover>
      <TableCell align="left">{name}</TableCell>
      <TableCell align="left">{addressStr}</TableCell>
      <TableCell align="left">{responsible_person.email || responsible_person.phone_number || '-'}</TableCell>
      <TableCell align={'right'}>
        <ManufacturerTableRowMoreMenu manufacturer={manufacturer} />
      </TableCell>
    </TableRow>
  );
}
