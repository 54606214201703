import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ManufacturerTable from './ManufacturerTable';

const ManufacturerCRUD: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between' }}>
        <h2>Producenci</h2>
        <Button variant="contained" onClick={() => navigate('/dashboard/manufacturer/new')}>
          Nowy producent
        </Button>
      </Box>

      <ManufacturerTable />
    </Box>
  );
};

export default ManufacturerCRUD;
