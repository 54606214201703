import {
  Box,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@mui/material';
import * as React from 'react';
import { useState } from 'react';
import Scrollbar from '../Scrollbar';
import SearchNotFound from '../SearchNotFound';
import GenericListHead from './GenericListHead';
import GenericToolbar from './GenericToolbar';

// ----------------------------------------------------------------------

export default function GenericTable({
  tableHead,
  rowComponent,
  rows,
  rowsCount,
  isLoading,
  filterConfiguration,
  filterValues,
  onFilterChange,
  page,
  onPageChange,
  rowsPerPage,
  onRowsPerPageChange,
  orderBy,
  onOrderByChange,
  sortOrder,
  onSortOrderChange,
}) {
  const [filterName, setFilterName] = useState('');

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && sortOrder === 'asc';
    onSortOrderChange(isAsc ? 'desc' : 'asc');
    onOrderByChange(property);
  };

  const handleChangeRowsPerPage = (event) => {
    onRowsPerPageChange(parseInt(event.target.value, 10));
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(property);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.size) : 0;
  const isNotFound = !rows.length && Boolean(filterName);

  return (
    <>
      <GenericToolbar configuration={filterConfiguration} onFilterChange={onFilterChange} values={filterValues} />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <GenericListHead
              order={sortOrder}
              orderBy={orderBy}
              headLabel={tableHead}
              createSortHandler={createSortHandler}
            />

            {!isLoading && (
              <TableBody>
                {rows.map((row) => rowComponent(row))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            )}

            {isLoading && (
              <TableBody>
                {[...Array(rowsPerPage)].map((i, index) => (
                  <TableRow key={`table-row-skeleton-${index}`}>
                    <TableCell colSpan={9}>
                      <Stack spacing={3} direction="row" alignItems="center">
                        <Skeleton style={{ width: 30, height: 53 }} variant="rounded" />
                        <Skeleton style={{ width: 50, height: 53 }} variant="rounded" />
                        <Skeleton style={{ width: 20, height: 53 }} variant="rounded" />
                        <Skeleton style={{ width: 30, height: 53 }} variant="rounded" />
                        <Skeleton style={{ width: 320, height: 53 }} variant="rounded" />
                        <Skeleton style={{ width: 100, height: 53 }} variant="rounded" />
                        <Skeleton style={{ width: 160, height: 53 }} variant="rounded" />
                        <Skeleton style={{ width: 120, height: 53 }} variant="rounded" />
                        <Skeleton style={{ width: 120, height: 53 }} variant="rounded" />
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            )}

            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6}>
                    <Box sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={filterName} />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50]}
        component="div"
        count={rowsCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(event, value) => onPageChange(value)}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={'Rozmiar strony:'}
        labelDisplayedRows={({ from, to, count }) => `${from}–${to} z ${count !== -1 ? count : `więcej niż ${to}`}`}
      />
    </>
  );
}
