import React, { useEffect, useState } from 'react';
import { Box, Button, TextField, MenuItem } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import uuidv4 from '../../../utils/uuidv4';
import axios from '../../../utils/axios';
import { countries } from '../../../_mock';

interface ManufacturerFormInputs {
  name: string;
  registered_trade_name: string;
  address: {
    country_code: string;
    street: string;
    postal_code: string;
    city: string;
  };
  responsible_person: {
    email: string;
    phone_number: string;
  };
}

const fakeApiDelay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const ManufacturerFormPage: React.FC = () => {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);

  const { control, handleSubmit, reset, watch } = useForm<ManufacturerFormInputs>({
    defaultValues: {
      name: '',
      registered_trade_name: '',
      address: {
        country_code: '',
        street: '',
        postal_code: '',
        city: '',
      },
      responsible_person: {
        email: '',
        phone_number: '',
      },
    },
  });

  // For editing, fetch the manufacturer details by id and reset the form values.
  useEffect(() => {
    const fetchManufacturer = async (id: string) => {
      setLoading(true);
      try {
        const response = await axios.get(`/api/manufacturers/${id}`);
        const data = response.data;
        const fetchedData: ManufacturerFormInputs = {
          name: data.name,
          registered_trade_name: data.registered_trade_name,
          address: {
            country_code: data.address.country_code,
            street: data.address.street,
            postal_code: data.address.postal_code,
            city: data.address.city,
          },
          responsible_person: {
            email: data.responsible_person.email || '',
            phone_number: data.responsible_person.phone_number || '',
          },
        };
        reset(fetchedData);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchManufacturer(id);
    }
  }, [id, reset]);

  const onSubmit = async (data: ManufacturerFormInputs) => {
    setLoading(true);
    await fakeApiDelay(500);
    try {
      if (id) {
        // Update manufacturer
        console.log('Updating manufacturer:', id, data);
        await axios.put(`/api/manufacturers/${id}`, data);
      } else {
        // Create manufacturer
        const newManufacturer = { id: uuidv4(), ...data };
        console.log('Creating manufacturer:', newManufacturer);
        await axios.post('/api/manufacturers', newManufacturer);
      }
      navigate('/dashboard/manufacturers');
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate('/dashboard/manufacturers');
  };

  return (
    <Box sx={{ p: 2 }}>
      <h2>{id ? 'Edit Manufacturer' : 'New Manufacturer'}</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Manufacturer Name */}
          <Controller
            name="name"
            control={control}
            rules={{ required: 'Name is required' }}
            render={({ field, fieldState }) => (
              <TextField
                label="Name"
                fullWidth
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          {/* Registered Trade Name */}
          <Controller
            name="registered_trade_name"
            control={control}
            rules={{ required: 'Registered Trade Name is required' }}
            render={({ field, fieldState }) => (
              <TextField
                label="Registered Trade Name"
                fullWidth
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          {/* Address: Country Code as a Select */}
          <Controller
            name="address.country_code"
            control={control}
            rules={{ required: 'Country code is required' }}
            render={({ field, fieldState }) => (
              <TextField
                select
                label="Country"
                fullWidth
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              >
                <MenuItem key="country-empty" value="" />
                {countries.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
          {/* Address: Street */}
          <Controller
            name="address.street"
            control={control}
            rules={{ required: 'Street is required' }}
            render={({ field, fieldState }) => (
              <TextField
                label="Street"
                fullWidth
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          {/* Address: Postal Code */}
          <Controller
            name="address.postal_code"
            control={control}
            rules={{ required: 'Postal code is required' }}
            render={({ field, fieldState }) => (
              <TextField
                label="Postal Code"
                fullWidth
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          {/* Address: City */}
          <Controller
            name="address.city"
            control={control}
            rules={{ required: 'City is required' }}
            render={({ field, fieldState }) => (
              <TextField
                label="City"
                fullWidth
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
              />
            )}
          />
          {/* Responsible Person: Email */}
          <Controller
            name="responsible_person.email"
            control={control}
            rules={{
              validate: (value) => {
                const phone = watch('responsible_person.phone_number');
                if (!value && !phone) {
                  return 'Either email or phone number is required';
                }
                if (value && !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)) {
                  return 'Invalid email address';
                }
                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                label="Responsible Person Email"
                fullWidth
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || 'Optional if phone number provided'}
              />
            )}
          />
          {/* Responsible Person: Phone Number */}
          <Controller
            name="responsible_person.phone_number"
            control={control}
            rules={{
              validate: (value) => {
                const email = watch('responsible_person.email');
                if (!value && !email) {
                  return 'Either email or phone number is required';
                }
                return true;
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                label="Responsible Person Phone Number"
                fullWidth
                {...field}
                error={!!fieldState.error}
                helperText={fieldState.error?.message || 'Optional if email provided'}
              />
            )}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button type="submit" variant="contained" sx={{ ml: 1 }} disabled={loading}>
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ManufacturerFormPage;
