import { m } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Button,
  Dialog,
  Select,
  MenuItem,
  Checkbox,
  Container,
  Typography,
  InputLabel,
  DialogTitle,
  FormControl,
  ListItemText,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
// routes
import { useRef, useState } from 'react';
import * as Yup from 'yup';
import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
// components
import { MotionContainer, varFade } from '../../components/animate';
import { RHFTextField, FormProvider } from '../../components/hook-form';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { SentIcon } from '../../assets';

const MARKETPLACE_OPTIONS = ['Allegro', 'Erli', 'Empik', 'Inne'];
// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.grey[400],
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}));

const ContentStyle = styled((props) => <Stack spacing={5} {...props} />)(({ theme }) => ({
  zIndex: 10,
  maxWidth: 520,
  margin: 'auto',
  textAlign: 'center',
  position: 'relative',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    margin: 'unset',
    textAlign: 'left',
  },
}));

const HeroOverlayStyle = styled(m.img)({
  zIndex: 9,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

const HeroImgStyle = styled(m.img)(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: 8,
  width: '100%',
  margin: 'auto',
  position: 'absolute',
  [theme.breakpoints.up('lg')]: {
    right: '8%',
    width: 'auto',
    height: '48vh',
  },
}));

// ----------------------------------------------------------------------

export default function HomeHero() {
  const [open, setOpen] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const isMountedRef = useIsMountedRef();

  const ClientLeadSchema = Yup.object().shape({
    name: Yup.string().required('Imię jest wymagane'),
    email: Yup.string().email('Podaj poprawny adres e-mail').required('Adres e-mail jest wymagany'),
    phone: Yup.string()
      .matches(/^[0-9]+$/, 'Numer telefonu może zawierać tylko cyfry')
      .required('Numer telefonu jest wymagany'),
    ordersPerMonth: Yup.string()
      .oneOf(['1-1000', '1000-5000', '5000+'], 'Wybierz poprawny zakres zamówień')
      .required('Wybierz poprawny zakres zamówień'),
    marketplaces: Yup.array().min(1, 'Wybierz co najmniej jedną platformę sprzedaży'),
    other: Yup.string().when('marketplaces', {
      is: (marketplaces) => marketplaces.includes('Inne'),
      then: Yup.string().required('Podaj inne platformy sprzedaży'),
    }),
  });

  const defaultValues = {
    name: '',
    email: '',
    phone: '',
    ordersPerMonth: '',
    marketplaces: [],
    other: '',
  };

  const methods = useForm({
    resolver: yupResolver(ClientLeadSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      console.log(`${data.name} ${data.email}`);
      setFormSubmitted(true);
      // await login(data.email, data.password);
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };

  const featuresRef = useRef(null);
  const handleScrollToFeatures = () => {
    if (featuresRef.current) {
      featuresRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <MotionContainer>
      {/*<RootStyle>*/}
      {/*<HeroOverlayStyle*/}
      {/*  alt="overlay"*/}
      {/*  src="https://minimal-assets-api.vercel.app/assets/overlay.svg"*/}
      {/*  variants={varFade().in}*/}
      {/*/>*/}

      {/*<HeroImgStyle*/}
      {/*  alt="hero"*/}
      {/*  src="https://minimal-assets-api.vercel.app/assets/images/home/hero.png"*/}
      {/*  variants={varFade().inUp}*/}
      {/*/>*/}

      <Box
        sx={{
          width: '100%',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          backgroundColor: 'background.default',
        }}
      >
        <Container maxWidth="md" sx={{ textAlign: 'center', py: { xs: 8, sm: 12 } }}>
          <Typography variant="h2" sx={{ my: 4 }}>
            <Typography component="span" variant="h2" sx={{ opacity: 0.3 }}>
              Automatyzuj, skaluj i optymalizuj
            </Typography>
            <br /> swój e-commerce z
            <Typography component="span" variant="h2" sx={{ color: 'primary.main' }}>
              &nbsp;RetailHub
            </Typography>
          </Typography>
          <Typography variant="body1" sx={{ mb: 8, color: 'text.secondary' }}>
            Kompleksowa platforma do zarządzania e-commerce, która integruje się z marketplace’ami,
            <br />
            upraszcza przetwarzanie zamówień i usprawnia sprzedaż na wielu kanałach.
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center" alignItems="center">
            <Button variant="contained" color="primary" size="large" onClick={() => setOpen(true)}>
              Chcę dostęp
            </Button>
            <Button variant="outlined" color="primary" size="large" onClick={handleScrollToFeatures}>
              Dowiedz się więcej
            </Button>
          </Stack>
        </Container>

        {/* Key Features Section */}
        <Container
          ref={featuresRef}
          maxWidth="lg"
          sx={{
            py: { xs: 4, md: 8 },
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          <Box textAlign="center">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Kluczowe cechy
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Zaprojektowany, aby oszczędzać Twój czas i umożliwić dynamiczny rozwój Twojej firmy.
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: { xs: '1fr', md: '1fr 1fr 1fr' },
              gap: 4,
            }}
          >
            {/* 1. Marketplace Sync */}
            <Box
              sx={{
                p: 3,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6">Synchronizacja z Allegro</Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                Synchronizuj automatycznie oferty, ceny i stany magazynowe z Allegro.
              </Typography>
            </Box>

            {/* 2. Order Management */}
            <Box
              sx={{
                p: 3,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6">Zarządzanie zamówieniami</Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                Przeglądaj wszystkie zamówienia w jednym miejscu, śledź ich realizację i sprawnie obsługuj zwroty.
              </Typography>
            </Box>

            {/* 3. Supplier Integration & Dropshipping */}
            <Box
              sx={{
                p: 3,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6">Dropshipping i integracja z dostawcami</Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                Zautomatyzuj procesy zakupowe i zarządzaj logistyką dropshippingu.
              </Typography>
            </Box>

            {/* Warehouse & Inventory */}
            <Box
              sx={{
                p: 3,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6">Magazyn i zarządzanie stanami</Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                Monitoruj poziomy zapasów, lokalizacje produktów i otrzymuj powiadomienia o niskiej dostępności, aby
                uzupełnić stan.
              </Typography>
            </Box>

            {/* Client Communication */}
            <Box
              sx={{
                p: 3,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6">Komunikacja z klientami</Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                Komunikuj się z klientami poprzez wbudowany czat do obsługi zapytań klientów.
              </Typography>
            </Box>

            {/* Sales & Performance Analytics */}
            <Box
              sx={{
                p: 3,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 2,
                textAlign: 'center',
              }}
            >
              <Typography variant="h6">Analityka sprzedaży i wydajności</Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'text.secondary' }}>
                Śledź wyniki sprzedaży oraz trendy produktowe, aby podejmować skuteczniejsze decyzje.
              </Typography>
            </Box>
          </Box>
        </Container>

        {/* CTA Section */}
        <Box
          sx={{
            py: { xs: 6, md: 8 },
            textAlign: 'center',
            backgroundColor: 'background.paper',
          }}
        >
          <Container maxWidth="sm">
            <Typography variant="h4" sx={{ mb: 2 }}>
              Uzyskaj dostęp do
              <Typography component="span" variant="h4" sx={{ color: 'primary.main' }}>
                &nbsp;RetailHub
              </Typography>
              &nbsp;Early Access
            </Typography>
            <Typography variant="body1" sx={{ mb: 4, color: 'text.secondary' }}>
              Zapisz się już dziś! Liczba miejsc ograniczona.
              <br />
              Skorzystaj z przewagi technologicznej przed konkurencją!
            </Typography>
            <Button variant="contained" color="primary" size="large" onClick={() => setOpen(true)}>
              Chcę dostęp
            </Button>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>Dostęp do RetailHub Early Access</DialogTitle>
                <DialogContent>
                  {formSubmitted ? (
                    <Typography variant="body1" align="center" sx={{ pt: 3 }}>
                      Dziękujemy za zainteresowanie!
                      <br />
                      Skontaktujemy się z Tobą w ciągu kilku dni.
                    </Typography>
                  ) : (
                    <Stack spacing={2} sx={{ pt: 2 }}>
                      <RHFTextField name="name" label="Imię" />
                      <RHFTextField name="email" label="Adres e-mail" />
                      <RHFTextField name="phone" label="Numer telefonu" />

                      <Controller
                        name="ordersPerMonth"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl fullWidth error={!!error}>
                            <InputLabel>Ile sprzedajesz zamówień miesięcznie?</InputLabel>
                            <Select {...field} displayEmpty>
                              <MenuItem value="1-1000">1-1000</MenuItem>
                              <MenuItem value="1000-5000">1000-5000</MenuItem>
                              <MenuItem value="5000+">5000+</MenuItem>
                            </Select>
                            {error && (
                              <Typography variant="caption" sx={{ pl: 2, pt: 1 }} color="error">
                                {error.message}
                              </Typography>
                            )}
                          </FormControl>
                        )}
                      />

                      <Controller
                        name="marketplaces"
                        control={methods.control}
                        render={({ field, fieldState: { error } }) => (
                          <FormControl fullWidth error={!!error}>
                            <InputLabel>Jakich kanałów sprzedaży używasz?</InputLabel>
                            <Select multiple {...field} renderValue={(selected) => selected.join(', ')} displayEmpty>
                              {MARKETPLACE_OPTIONS.map((option) => (
                                <MenuItem key={option} value={option}>
                                  <Checkbox checked={field.value?.includes(option)} />
                                  <ListItemText primary={option} />
                                </MenuItem>
                              ))}
                            </Select>
                            {error && (
                              <Typography variant="caption" sx={{ pl: 2, pt: 1 }} color="error">
                                {error.message}
                              </Typography>
                            )}
                          </FormControl>
                        )}
                      />

                      {/* Show 'Other' field only if 'Other' is selected */}
                      {methods.watch('marketplaces')?.includes('Inne') && (
                        <RHFTextField name="other" label="Podaj inne platformy sprzedaży" />
                      )}
                    </Stack>
                  )}
                </DialogContent>
                <DialogActions>
                  {formSubmitted ? (
                    <Button variant="contained" onClick={() => setOpen(false)}>
                      Close
                    </Button>
                  ) : (
                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={isSubmitting}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Submit
                    </LoadingButton>
                  )}
                </DialogActions>
              </Dialog>
            </FormProvider>
          </Container>
        </Box>
      </Box>

      {/*<Container>*/}
      {/*  <ContentStyle>*/}
      {/*<m.div variants={varFade().inRight}>*/}
      {/*  <Typography variant="h1" sx={{ color: 'common.white' }}>*/}
      {/*    Start a <br />*/}
      {/*    new project <br /> with*/}
      {/*    <Typography component="span" variant="h1" sx={{ color: 'primary.main' }}>*/}
      {/*      &nbsp;Minimal*/}
      {/*    </Typography>*/}
      {/*  </Typography>*/}
      {/*</m.div>*/}

      {/*<m.div variants={varFade().inRight}>*/}
      {/*  <Typography sx={{ color: 'common.white' }}>*/}
      {/*    The starting point for your next project based on easy-to-customize MUI helps you build apps faster and*/}
      {/*    better.*/}
      {/*  </Typography>*/}
      {/*</m.div>*/}

      {/*<Stack spacing={2.5} alignItems="center" direction={{ xs: 'column', md: 'row' }}>*/}
      {/*  <m.div variants={varFade().inRight}>*/}
      {/*    <TextIconLabel*/}
      {/*      icon={*/}
      {/*        <Image*/}
      {/*          alt="sketch icon"*/}
      {/*          src="https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch_small.svg"*/}
      {/*          sx={{ width: 20, height: 20, mr: 1 }}*/}
      {/*        />*/}
      {/*      }*/}
      {/*      value={*/}
      {/*        <Link*/}
      {/*          href="https://www.sketch.com/s/0fa4699d-a3ff-4cd5-a3a7-d851eb7e17f0"*/}
      {/*          target="_blank"*/}
      {/*          rel="noopener"*/}
      {/*          color="common.white"*/}
      {/*          sx={{ typography: 'body2' }}*/}
      {/*        >*/}
      {/*          Preview Sketch*/}
      {/*        </Link>*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </m.div>*/}

      {/*  <m.div variants={varFade().inRight}>*/}
      {/*    <TextIconLabel*/}
      {/*      icon={*/}
      {/*        <Image*/}
      {/*          alt="sketch icon"*/}
      {/*          src="https://minimal-assets-api.vercel.app/assets/images/home/ic_figma_small.svg"*/}
      {/*          sx={{ width: 20, height: 20, mr: 1 }}*/}
      {/*        />*/}
      {/*      }*/}
      {/*      value={*/}
      {/*        <Link*/}
      {/*          href="https://www.figma.com/file/x7earqGD0VGFjFdk5v2DgZ/%5BPreview%5D-Minimal-Web?node-id=866%3A55474"*/}
      {/*          target="_blank"*/}
      {/*          rel="noopener"*/}
      {/*          color="common.white"*/}
      {/*          sx={{ typography: 'body2' }}*/}
      {/*        >*/}
      {/*          Preview Figma*/}
      {/*        </Link>*/}
      {/*      }*/}
      {/*    />*/}
      {/*  </m.div>*/}
      {/*</Stack>*/}

      {/*<m.div variants={varFade().inRight}>*/}
      {/*  <Button*/}
      {/*    size="large"*/}
      {/*    variant="contained"*/}
      {/*    component={RouterLink}*/}
      {/*    to={PATH_DASHBOARD.root}*/}
      {/*    startIcon={<Iconify icon={'eva:flash-fill'} width={20} height={20} />}*/}
      {/*  >*/}
      {/*    Live Preview*/}
      {/*  </Button>*/}
      {/*</m.div>*/}

      {/*<Stack spacing={2.5}>*/}
      {/*  <m.div variants={varFade().inRight}>*/}
      {/*    <Typography variant="overline" sx={{ color: 'primary.light' }}>*/}
      {/*      Available For*/}
      {/*    </Typography>*/}
      {/*  </m.div>*/}

      {/*  <Stack direction="row" spacing={1.5} justifyContent={{ xs: 'center', md: 'flex-start' }}>*/}
      {/*    {['ic_sketch', 'ic_figma', 'ic_js', 'ic_ts', 'ic_nextjs'].map((resource) => (*/}
      {/*      <m.img*/}
      {/*        key={resource}*/}
      {/*        variants={varFade().inRight}*/}
      {/*        src={`https://minimal-assets-api.vercel.app/assets/images/home/${resource}.svg`}*/}
      {/*      />*/}
      {/*    ))}*/}
      {/*  </Stack>*/}
      {/*</Stack>*/}

      {/*  </ContentStyle>*/}
      {/*</Container>*/}
      {/*</RootStyle>*/}
      {/*<Box sx={{ height: { md: '100vh' } }} />*/}
    </MotionContainer>
  );
}
