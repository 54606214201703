import React, { useState, useCallback, MouseEvent } from 'react';
import { IconButton, MenuItem } from '@mui/material';
import { useSnackbar } from 'notistack';
import MenuPopover from '../../../components/MenuPopover';
import Iconify from '../../../components/Iconify';
import { APIManufacturer } from '../../../api/types/Manufacturer';
import axios from '../../../utils/axios';

interface ManufacturerTableRowMoreMenuProps {
  manufacturer: APIManufacturer;
}

export default function ManufacturerTableRowMoreMenu({ manufacturer }: ManufacturerTableRowMoreMenuProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const ICON = {
    mr: 2,
    width: 20,
    height: 20,
  };

  const deleteManufacturer = useCallback(
    async (id: string) => {
      try {
        const response = await axios.delete(`/manufacturers/${id}`);
        console.log(response);
        enqueueSnackbar('Producent usunięty!');
      } catch (error) {
        console.error(error);
        enqueueSnackbar('Nie udało się usunąć producenta!', { variant: 'error' });
      }
    },
    [enqueueSnackbar]
  );

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Iconify icon={'eva:more-vertical-fill'} sx={{ width: 20, height: 20 }} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        arrow="right-top"
        disabledArrow={false}
        sx={{
          mt: -1,
          width: 160,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <MenuItem component="a" href={`/dashboard/manufacturers/edit/${manufacturer.id}`} onClick={handleClose}>
          <Iconify icon={'eva:edit-fill'} sx={{ ...ICON }} />
          Edytuj producenta
        </MenuItem>
        <MenuItem
          onClick={() => {
            deleteManufacturer(manufacturer.id);
            handleClose();
          }}
        >
          <Iconify icon={'eva:trash-2-outline'} sx={{ ...ICON }} />
          Usuń producenta
        </MenuItem>
      </MenuPopover>
    </>
  );
}
