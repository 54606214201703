export class ToolbarFilterValues {
  constructor(initialFilters = {}) {
    this.filters = initialFilters;
  }

  getValue(filterId) {
    return this.filters[filterId];
  }

  setValue(filterId, value) {
    this.filters[filterId] = value;
  }

  removeValue(filterId) {
    delete this.filters[filterId];
  }

  getAllValues() {
    return this.filters;
  }

  setValues(newValues) {
    this.filters = { ...this.filters, ...newValues };
  }

  serialize() {
    return JSON.stringify(this.filters);
  }
}
