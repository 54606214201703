import * as React from 'react';
import { useLocation } from 'react-router-dom';
import GenericRestResourceTable from '../../../components/table/GenericRestResourceTable';
import { ToolbarFilterValues } from '../../../components/table/filter/ToolbarFilterValues';
import { ToolbarFilterConfiguration } from '../../../components/table/filter/ToolbarFilterConfiguration';
import ManufacturerTableRow from './ManufacturerTableRow';

const TABLE_HEAD = [
  { id: 'name', label: 'Nazwa', alignRight: false },
  { id: 'address', label: 'Adres', alignRight: false },
  { id: 'responsible_person', label: 'Osoba odpowiedzialna', alignRight: false },
  { id: 'actions' },
];

// ----------------------------------------------------------------------

export default function ManufacturerTable() {
  const location = useLocation();
  // const params = new URLSearchParams(location.search);

  const filterConfiguration = new ToolbarFilterConfiguration([]);

  const defaultFilterValues = new ToolbarFilterValues({});

  return (
    <GenericRestResourceTable
      resourceName="manufacturers"
      filterConfiguration={filterConfiguration}
      defaultFilterValues={defaultFilterValues}
      rowComponentFn={(row) => <ManufacturerTableRow key={`manufacturer-row-${row.id}`} manufacturer={row} />}
      tableHead={TABLE_HEAD}
    />
  );
}
