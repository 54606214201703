export class ToolbarFilterConfiguration {
  constructor(configuration) {
    this.filters = configuration;
  }

  toURLQuery(filterValues) {
    return this.filters.reduce(
      (params, filter) => ({
        ...params,
        ...filter.serialize(filterValues.getValue(filter.id)),
      }),
      {}
    );
  }

  toRESTParams(filterValues) {
    return this.filters.reduce(
      (params, filter) => ({
        ...params,
        ...filter.toRESTParam(filterValues.getValue(filter.id)),
      }),
      {}
    );
  }
}
