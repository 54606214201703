import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { Button, Checkbox, Collapse, FormControlLabel, IconButton, Select, Stack } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Label from '../Label';
import InputStyle from '../InputStyle';
import { ToolbarFilterConfiguration } from './filter/ToolbarFilterConfiguration';
import { ToolbarFilterValues } from './filter/ToolbarFilterValues';
import Iconify from '../Iconify';

SelectDropdownFilter.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.object,
  values: PropTypes.array,
  onValueChange: PropTypes.func,
};

function SelectDropdownFilter({ id, name, value, values, onValueChange }) {
  const theme = useTheme();

  return (
    <FormControl sx={{ minWidth: 200 }}>
      <InputLabel>{name}</InputLabel>
      <Select
        id={id}
        value={value}
        label={name}
        onChange={(event) => {
          onValueChange(event.target.value);
        }}
      >
        {values.map((s) => (
          <MenuItem key={`${id}-${s.label}-menu-item`} value={s}>
            <Label
              sx={{ textTransform: 'uppercase' }}
              variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
              color={s.color}
            >
              {s.label}
            </Label>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

InputFilter.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholderValue: PropTypes.string,
  inputProps: PropTypes.any,
  onValueChange: PropTypes.func,
};

function InputFilter({ id, value, placeholderValue, inputProps, onValueChange }) {
  return (
    <InputStyle
      id={id}
      value={value}
      onChange={(event) => onValueChange(event.target.value)}
      placeholder={placeholderValue}
      InputProps={inputProps}
    />
  );
}

CheckboxFilter.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  onValueChange: PropTypes.func,
};

function CheckboxFilter({ id, name, value, onValueChange }) {
  return (
    <FormControlLabel
      required
      control={<Checkbox id={id} checked={value} />}
      onChange={(event, checked) => onValueChange(checked)}
      label={name}
    />
  );
}

GenericToolbar.propTypes = {
  configuration: PropTypes.shape(ToolbarFilterConfiguration),
  values: PropTypes.shape(ToolbarFilterValues),
  onFilterChange: PropTypes.func,
};
export default function GenericToolbar({ configuration, values, onFilterChange }) {
  const [showAdvanced, setShowAdvanced] = React.useState(false);

  const handleValueChange = (fieldId, newVal) => {
    values.setValue(fieldId, newVal);
    onFilterChange(values);
  };

  // separate main vs advanced filters
  const mainFilters = configuration.filters.filter((f) => !f.advanced);
  const advancedFilters = configuration.filters.filter((f) => f.advanced);

  const handleToggleAdvanced = () => {
    setShowAdvanced((prev) => !prev);
  };

  return (
    <Stack spacing={1} sx={{ p: 2 }}>
      {/*
        Row with main filters + icon on the right
        Use a horizontal stack with space-between to keep icon on the far right
      */}
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {/* main filters in a row */}
        <Stack direction="row" spacing={2}>
          {mainFilters.map((field) => (
            <FilterComponent
              key={`generic-toolbar-${field.id}`}
              field={field}
              value={values.getValue(field.id)}
              onValueChange={(val) => handleValueChange(field.id, val)}
            />
          ))}
        </Stack>

        {/* Only show icon if there are advanced filters */}
        {advancedFilters.length > 0 && (
          <IconButton onClick={handleToggleAdvanced} size="small">
            <Iconify icon={'eva:arrowhead-down-outline'} width={20} height={20} />
          </IconButton>
        )}
      </Stack>

      {/*
        Advanced filters in a collapse
        - no extra margin when collapsed
        - only show spacing if expanded
      */}
      <Collapse in={showAdvanced} unmountOnExit>
        <Stack direction="row" spacing={2} sx={{ mt: 1 }}>
          {advancedFilters.map((field) => (
            <FilterComponent
              key={`generic-toolbar-${field.id}`}
              field={field}
              value={values.getValue(field.id)}
              onValueChange={(val) => handleValueChange(field.id, val)}
            />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
}

// Extract the “render each filter” logic into a helper
function FilterComponent({ field, value, onValueChange }) {
  if (field.type === 'select-dropdown') {
    return <SelectDropdownFilter {...field} value={value} onValueChange={onValueChange} />;
  }
  if (field.type === 'fts-search') {
    return <InputFilter {...field} value={value} onValueChange={onValueChange} />;
  }
  if (field.type === 'checkbox') {
    return <CheckboxFilter {...field} value={value} onValueChange={onValueChange} />;
  }
  return null;
}
