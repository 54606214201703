import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import rawAxios from 'axios';
import axios from '../../utils/axios';
import GenericTable from './GenericTable';
import { ToolbarFilterConfiguration } from './filter/ToolbarFilterConfiguration';
import { ToolbarFilterValues } from './filter/ToolbarFilterValues';

// ----------------------------------------------------------------------

GenericRestResourceTable.propTypes = {
  resourceName: PropTypes.string,
  rowComponentFn: PropTypes.func,
  filterConfiguration: PropTypes.shape(ToolbarFilterConfiguration),
  defaultFilterValues: PropTypes.shape(ToolbarFilterValues),
  tableHead: PropTypes.array,
  requestParams: PropTypes.object,
  defaultRowsPerPage: PropTypes.number,
};
export default function GenericRestResourceTable({
  resourceName,
  requestParams,
  rowComponentFn,
  filterConfiguration,
  defaultFilterValues,
  tableHead,
  defaultRowsPerPage = 20,
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(0);
  const [page, setPage] = useState(Number(params.get('page')) || 0);
  const [sortOrder, setSetOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(Number(params.get('rowsPerPage')) || defaultRowsPerPage);

  const [filterValues, setFilterValues] = useState(defaultFilterValues);

  useEffect(() => {
    const cancelTokenSource = rawAxios.CancelToken.source();
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const filterParams = filterConfiguration.toRESTParams(filterValues);
        const restParams = {
          page,
          page_size: rowsPerPage,
          ...filterParams,
          ...requestParams
        };

        if (orderBy) {
          restParams.sort_order = sortOrder;
          restParams.order_by = orderBy;
        }

        const resourceResponse = await axios.get(`/api/${resourceName}`, {
          params: restParams,
          cancelToken: cancelTokenSource.token,
        });
        const countParams = {
          ...filterParams,
          ...requestParams
        };
        const resourceCountResponse = await axios.get(`/api/${resourceName}/count`, {
          params: countParams,
          cancelToken: cancelTokenSource.token,
        });
        setRows(resourceResponse.data);
        setRowsCount(resourceCountResponse.data);
      } catch (error) {
        if (!rawAxios.isCancel(error)) {
          console.error(`Failed to fetch resource ${resourceName}:`, error);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      cancelTokenSource.cancel('Operation canceled due to new request.');
    };
  }, [page, rowsPerPage, sortOrder, orderBy, filterValues.serialize()]);

  const navigatePage = (filterValues, page, rowsPerPage) => {
    const queryParams = {
      ...filterConfiguration.toURLQuery(filterValues),
    };
    if (page !== 0) {
      queryParams.page = page;
    }
    if (rowsPerPage !== defaultRowsPerPage) {
      queryParams.rowsPerPage = rowsPerPage;
    }
    navigate({
      pathname: location.pathname,
      search: `?${new URLSearchParams(queryParams)}`,
    });
  };

  const onFilterChange = (filterValues) => {
    setFilterValues(filterValues);
    navigatePage(filterValues, page, rowsPerPage);
  };

  const handlePageChange = (page) => {
    setPage((_) => {
      navigatePage(filterValues, page, rowsPerPage);
      return page;
    });
  };

  const handleRowsPerPageChange = (rowsPerPage) => {
    setRowsPerPage((_) => {
      navigatePage(filterValues, page, rowsPerPage);
      return rowsPerPage;
    });
  };

  return (
    <GenericTable
      tableHead={tableHead}
      rowComponent={rowComponentFn}
      rows={rows}
      rowsCount={rowsCount}
      isLoading={isLoading}
      filterConfiguration={filterConfiguration}
      filterValues={filterValues}
      onFilterChange={onFilterChange}
      page={page}
      onPageChange={handlePageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleRowsPerPageChange}
      sortOrder={sortOrder}
      onSortOrderChange={setSetOrder}
      orderBy={orderBy}
      onOrderByChange={setOrderBy}
    />
  );
}
